import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* Header Section */
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: ${props => props.color || props.theme.background};
`;

export const Cover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.color || props.theme.header};
  min-height: 15vh;
`;

export const Avatar = styled.img`
  position: absolute;
  height: 15vh;
  width: 15vh;
  border-radius: 50%;
  border: solid;
  border-width: ${props => props.width || props.theme.avatar_width};
  border-color: ${props => props.color || props.theme.avatar_border};
  margin-top: 15vh;
`;

/* Content Section */
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8vh;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  margin: 0;
  padding-top: 1.5em;
  font-weight: bold;
  font-size: 2em;
  color: ${props => props.theme.text.title};
  text-align: center;
`;

export const Subtitle = styled.p`
  margin: 0;
  font-weight: normal;
  font-size: 1.1em;
  color: ${props => props.theme.text.subtitle};
  text-align: center;
`;

export const SocialGroup = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 0.5em;
  padding-top: 0.5em;
`;

export const SocialButton = styled.a`
  display: flex;
  height: 2.5em;
  width: 2.5em;
  border-radius: 50%;
  border: none;
  align-items: center;
  justify-content: center;
`;

export const SocialIcon = styled(FontAwesomeIcon)`
  font-size: 1.5em;
  padding-left: 1px;
  color: #FFFFFF;
`;

export const InstagramButton = styled(SocialButton)`
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(
      #6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%
    );
`;

export const FacebookButton = styled(SocialButton)`
  background: ${props => props.theme.button_social.facebook};
`;

export const WhatsappButton = styled(SocialButton)`
  background: ${props => props.theme.button_social.whatsapp};
`;

export const YoutubeButton = styled(SocialButton)`
  background: ${props => props.theme.button_social.youtube};
`;

export const WebsiteButton = styled(SocialButton)`
  background: ${props => props.theme.button_social.website};
`;

/* Links Section */
export const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5em;
`;

export const LinkContainer = styled.a`
  display: flex;
  font: inherit;
  width: 22em;
  height: 4em;
  margin-top: 1.1em;
  background-color: ${props => props.color || props.theme.button_link.background};
  color: ${props => props.theme.button_link.color};
  box-shadow: 0px 0.2em 0.7em 0px #00000030;
  border: solid;
  border-width: ${props => props.width || props.theme.button_link.border_width};
  border-color: ${props => props.color || props.theme.button_link.border_color};
  border-radius: 7.5em;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: ${props => props.theme.button_link.hover};
    color: ${props => props.theme.button_link.hoverColor};
    transition: .3s all;
  }
`;

export const LinkText = styled.p`
  font-size: 1.3em;
  font-weight: normal;
  text-align: center;
`;

export const LinkIcon = styled(FontAwesomeIcon)`
  margin-left: 0.9em;
  margin-right: 0.8em;
  font-size: 1.3em;
  color: ${props => props.theme.button_link.icon_color};
  font-weight: normal;

  ${LinkContainer}:hover & {
    color: ${props => props.theme.button_link.hoverColor};
    transition: .3s all;
  }
`;

export const StyledSvgContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 0.8em;
`;

export const StyledSvg = styled.svg`
  circle {
    fill: ${props => (props.fillColor ? props.fillColor : 'white')};
  }

  path {
    stroke: #030B1A;
    stroke-width: 1.9;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
`;

/* Footer Section */
export const Footer = styled.div`
  display: flex;
  margin-top: 10vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
`;

export const FooterIcon = styled.img`
  width: 120px;
  margin-bottom: 10vh;
`;

/* Loading Spinner */
export const LoaderContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
`;

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  width: 8vw;
  height: 8vw;
  border: 0.8em solid;
  border-color: #00c3a5 transparent #00c3a5 transparent;
  border-radius: 50%;
  animation: ${spinAnimation} 1.2s linear infinite;
`;