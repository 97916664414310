import React from 'react'

import {
  Container,
  Cover,
  Avatar,
  Content,
  ContentHeader,
  Title,
  Subtitle,
  SocialGroup,
  LinkGroup,
} from './styles'

import { SocialButton } from '../../Components/SocialButton'
import { LinkButton } from '../../Components/LinkButton'
import { FooterComponent } from '../../Components/Footer'

import { analytics } from '../../Config/firebase'
import { logEvent } from 'firebase/analytics'


function Home({ data }) {
  return (
    <Container>
      <Cover>
        <Avatar src={data.profileUrl} alt="" />
      </Cover>
      <Content>
        <ContentHeader>
          <Title>{data.title}</Title>
          <Subtitle>{data.subtitle}</Subtitle>
          <SocialGroup>
            {data.socials.map((social, index) => <SocialButton key={index} social={social} eventLogger={(v) => logEvent(analytics, v)} />)}
          </SocialGroup>
        </ContentHeader>
        <LinkGroup>
          {data.customLinks.map((link, index) => <LinkButton key={index} link={link} eventLogger={(v) => logEvent(analytics, v)} />)}
        </LinkGroup>
      </Content>
      <FooterComponent data={data?.customStyles?.doctoralia_logo} />
    </Container>
  )
}

export default Home