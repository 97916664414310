import React from 'react'
import { LinkContainer, LinkIcon, LinkText, StyledSvg, StyledSvgContainer } from "../Pages/Home/styles";

export function LinkButton({ link, color, eventLogger }) {
  const removeSpaceAndLowerCase = (str) => str.replace(/\s/g, '_').toLowerCase()
  return (
    <LinkContainer color={color?.backgroundColor} highlightColor={color?.highlightColor} href={link.url} onClick={() => eventLogger(link?.linkTypes?.name || "click_link_" + removeSpaceAndLowerCase(link?.description))}>
      <LinkIcon icon={['fal', link.customIcon]} linkColor={color?.highlightColor} hoverColor={color?.backgroundColor} />
      <LinkText>{link.description}</LinkText>
      <StyledSvgContainer>
        <StyledSvg xmlns="http://www.w3.org/2000/svg" width="50" height="49" viewBox="0 0 50 49" fill="none">
          <circle cx="25.0064" cy="24.6505" r="24.3174" />
          <path d="M18.3019 31.3571L31.7129 17.9461" />
          <path d="M31.713 30.5897V17.9457H19.069" />
        </StyledSvg>
      </StyledSvgContainer>
    </LinkContainer>
  )
}