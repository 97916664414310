import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAGX86k6ar6zeY1z2sZFGplGTXPgTmoa4k",
  authDomain: "doc-bio.firebaseapp.com",
  projectId: "doc-bio",
  storageBucket: "doc-bio.appspot.com",
  messagingSenderId: "1179064257",
  appId: "1:1179064257:web:da23524c2d9c9026fdc140",
  measurementId: "G-W20FHGHY4L"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

