export const Default  = {
  background: '#FFFFFF',
  header: '#FFFFFF',
  highlight: '#BFBFBF',
  accent: '#212121',
  avatar_border: '#BFBFBF',
  avatar_width: '1px',
  doctoralia_logo: 'gray', // gray, white, turquoise
  text: {
    title: '#23272D',
    subtitle: '#525F6E',
  },
  button_social: {
    facebook: '#3B5998',
    whatsapp: '#25D366',
    youtube: '#FF0000',
    website: '#000000'
  },
  button_link: {
    background: '#FFFFFF',
    icon_color: '#000000',
    text_color: '#000000',
    color: '#23272D',
    hover: '#000000',
    hoverColor: '#FFFFFF',
    border_color: '#BFBFBF',
    border_width: '1px',
  },
  font_weight: {
    thin: '100',
    light: '300',
    regular: '400',
    medium: '500',
    semiBold: '600',
    bold: '700',
    black: '900',
  },
}