import React from 'react'

import logoturquoise from '../Assets/Icons/logo-doctoralia-turquoise-rgb.svg'
import logowhite from '../Assets/Icons/logo-doctoralia-white-rgb.svg'
import logogray from '../Assets/Icons/logo-doctoralia-gray-rgb.svg'
import { Footer, FooterIcon } from '../Pages/Home/styles'

export function FooterComponent({ logo }) {
  return (
    <Footer>
      <FooterIcon src={
        logo === 'white' ? logowhite :
        logo === 'turquoise' ? logoturquoise :
        logogray
      } alt="" />
    </Footer>
  )
}
