import { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import axios from 'axios'

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import { Default } from '../Assets/Templates/Default';
import { GlobalStyle } from '../Assets/GlobalStyles';
import { DynamicTitle } from '../Components/DynamicTitle';
import { LoaderContainer, Spinner } from './Home/styles';
import Home from './Home';

function App() {
  const [data, setData] = useState({})
  const isLoading = !data.id

  useEffect(() => {
    const slug = window.location.href.split('/').pop()
    axios.get(`https://calendar-connector.feegow.com/dev/docbio/instances/${slug}`)
      .then(res => {
        setData(res.data)
      })
  }, [])

  if (data) { DynamicTitle(data.title + ' | Doctoralia') }

  const updatedTheme = { ...Default, ...data?.customStyles };

  return (
    <ThemeProvider theme={updatedTheme}>
      <GlobalStyle />
      {isLoading ? (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      ) : (
        <Home data={data} />
      )}
    </ThemeProvider>
  );
}
library.add(fas, fal, far, fab);

export default App;
