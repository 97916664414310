import React from 'react'

import {
  InstagramButton,
  FacebookButton,
  WhatsappButton,
  YoutubeButton,
  WebsiteButton,
  SocialIcon,
} from '../Pages/Home/styles.js'

const ICONS = {
  instagram: 'fa-instagram',
  facebook: 'fa-facebook-f',
  whatsapp: 'fa-whatsapp',
  youtube: 'fa-youtube',
  website: 'fa-globe',
}

export function SocialButton({ social, eventLogger }) {
  const socialName = social.name.toLowerCase()
  const iconClass = ICONS[socialName] || ''

  switch (socialName) {
    case 'instagram':
      return (
        <InstagramButton href={social.url} onClick={() => eventLogger("click_instagram")} >
          <SocialIcon icon={['fab', iconClass]} />
        </InstagramButton>
      )
    case 'facebook':
      return (
        <FacebookButton href={social.url} onClick={() => eventLogger("click_facebook")}>
          <SocialIcon icon={['fab', iconClass]} />
        </FacebookButton>
      )
    case 'whatsapp':
      return (
        <WhatsappButton href={social.url} onClick={() => eventLogger("click_whatsapp")}>
          <SocialIcon icon={['fab', iconClass]} />
        </WhatsappButton>
      )
    case 'youtube':
      return (
        <YoutubeButton href={social.url} onClick={() => eventLogger("click_youtube")}>
          <SocialIcon icon={['fab', iconClass]} />
        </YoutubeButton>
      )
    case 'website':
      return (
        <WebsiteButton href={social.url} onClick={() => eventLogger("click_website")}>
          <SocialIcon icon={['fal', iconClass]} />
        </WebsiteButton>
      )
    default:
      return null
  }
}
